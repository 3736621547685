import React, { useState } from 'react';

import 'assets/styles/pages/plantationregister.scss';

import Plantationbreadcumb from 'components/register-plantation/plantationbread';

import { planterdetailsData } from 'assets/data/planter-detail-list';

import { truncate } from 'lodash';

import Form from 'react-bootstrap/Form';

import { Link } from 'gatsby';

import { useForm } from "react-hook-form";

import axios from "axios";


import speciestreeicon from 'assets/images/icons/species-tree-icon.svg';

import closesmall from 'assets/images/icons/close-small-icon.svg';
import Viewthubnailslider from './viewthumbnail-slider';


import binicon from 'assets/images/icons/icon-metro-bin.svg';

import penciledit from 'assets/images/icons/icon-edit-pencil.svg';

import { ToastContainer, toast } from "react-toastify";
import { optionData } from 'assets/data/options';

const Plantationregister = () => {

  const [plantValue, setPlantValue] = useState("Multiple");
  const [countValue, setCountValue] = useState([]);
  const [addCount, setAddCount] = useState("");
  const [speiceOption, setSpeiceOption] = useState("Mango");
  const [addError, setAddError] = useState(false);
  const [addSamplingList, setAddSamplingList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [listShow, setListShow] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [countError, setCountError] = useState(false);
  const [succes, setSucces] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const plantTypeChange = (e) => {
    let targetValue = e.target.value
    setPlantValue(targetValue);

    if (targetValue === "Single") {
      setAddSamplingList([]);
      setAddCount("");
      setAddError(false)
    }
  }

  const speciesChange = async (e) => {
    await setSpeiceOption(e.target.value)
    setAddCount("")
  }
  const countChange = (e) => {
    e.preventDefault()
    let countVal = e.target.value

    const newArr = addSamplingList.map(obj => {
      const contVal = Number(obj.count) + Number(countVal)
      if (obj.option === speiceOption) {
        return { ...obj, count: contVal };
      }
      return obj;
    })
    setAddSamplingList(newArr);
    setAddCount(countVal)
  }
  const addSaplings = async (event) => {
    event.preventDefault();

    if (addCount !== "") {
      const addSap = [
        ...addSamplingList,
        {
          "count": addCount,
          "option": speiceOption,
        }
      ]
      await setAddSamplingList(addSap)
      setAddError(false)
      setAddCount("")
      setCountError(false)
    } else {
      setCountError(true)
    }
  }
  const removeList = (e) => {
    console.log(e.id, "Data Remove")
  }
  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };


  const fileLoad = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        const filesData = [
          ...filesList,
          { result },
        ]
        setFilesList(filesData)
        setFileError(false)
      })
      .catch(err => {
        console.log(err);
      });
  }
  const onSubmit = (data, event) => {
    event.preventDefault();
    if (plantValue === "Multiple" && addSamplingList.length === 0) {
      setAddError(true);
    }
    if (filesList.length === 0) {
      setFileError(true);
    }
    let values;
    if (addError === false && fileError === false) {
      if (plantValue === "Multiple" && addError === false && addSamplingList.length !== 0) {
        values = [...addSamplingList]
      }
      if (plantValue === "Single") {
        values = {
          "count": data.count,
          "option": speiceOption,
        }
      }
      let formData = {
        files: filesList,
        saplingsData: values
      }
      let storeValues = [{
        ...listShow,
        ...formData
      }]
      setListShow(storeValues)
      if (storeValues.length !== 0 && addError === false && fileError === false) {
        toast.success("Form submitted successfully");

      }
      setSucces(true);

      // axios.post("url", formData).then((response) => {
      //   if (response.status === 200) {

      //   } else {

      //   }
      // });
    } else {
      toast.error("something went wrong");
    }
    // setNextpage(true);


  }
  return (
    <>
      <div className='plantation-breadsection'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h3 className='register-text'> Register Your Plantation </h3>
              <h3 className='title-item'> GREEN KANHA INITIATIVE </h3>
              <h3 className='bred-regid'> Reg Id #101975841320 </h3>
              <h5 className='bred-datetime'> Tue, Apr 20, 2021, 12:45 AM </h5>
            </div>
            <div className='col-md-4 text-right not-activate'>
              <button className='btn btn-approve'> Send for Admin Approval </button>
              <button className='btn btn-add'> Add Saplings </button>
            </div>

            {listShow.length === "00000" ? <div className='row mg-top20'>
              {listShow.map((item) => {
                return (
                  <div className='col-md-5'>
                    <div className='iniative-card' key={item.id}>
                      <div className='wid-55'>
                        <Viewthubnailslider files={item.files} />
                      </div>

                      <div className='iniativecard-body wid-45'>
                        <p className='text-city'>
                          {truncate(item.trackid, {
                            length: 125,
                            omission: '...',
                          })}
                          <span className='text-right icon-rightposi'>
                            <img src={penciledit} className='img-fluid' alt='Edit Icon' />
                            <img src={binicon} className='img-fluid' alt='Delete Icon' />
                          </span>
                        </p>

                        <p className='date-text'>
                          {truncate(item.tracknumber, {
                            length: 125,
                            omission: '...',
                          })}
                        </p>

                        <p className='green-clr sapling-text'>
                          {truncate(item.desc, {
                            length: 125,
                            omission: '...',
                          })}
                        </p>
                        <p className='text-city'>
                          {truncate(item.planttype, {
                            length: 100,
                            omission: '...',
                          })}
                        </p>

                        <p className='date-text'>
                          {truncate(item.type, {
                            length: 125,
                            omission: '...',
                          })}
                        </p>
                        <p className='text-city'>
                          {truncate(item.species, {
                            length: 100,
                            omission: '...',
                          })}
                        </p>
                        <p className='date-text'>
                          {truncate(item.producttype, {
                            length: 125,
                            omission: '...',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
              : null}

            <div className='row'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='planttype' className='form-label'>
                        Plant type
                      </label>
                      <select name='planttype' id='planttype' className='form-control' onChange={plantTypeChange}>
                        <option value='Multiple'>Multiple</option>
                        <option value='Single'> Single </option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='Geotag' className='form-label'>
                        Geotag
                      </label>
                      <input type='text' name='Geotag'
                        {...register("Geotag")}
                        className='form-control ' id='Geotag' placeholder='' />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <h3 className='iniativecard-title'> ADD SPECIES DETAILS </h3>

                    {addSamplingList && addSamplingList.map(data => {
                      return (
                        <div className='dis-flex cent-item-mob mg-top20'>
                          <div className='green-selectbox'>
                            <span>
                              {' '}
                              <img src={speciestreeicon} className='img-fluid' alt='SPECIES DETAILS' />{' '}
                            </span>

                            <h5> {data.count} &nbsp; {data.option} </h5>
                            <span className='small-close' >
                              {' '}
                              <p id={data.option} value={data.option} onClick={(e) => removeList(e)}> <img src={closesmall} className='img-fluid' alt='Close small' />{' '}</p>
                            </span>
                          </div>&nbsp;&nbsp;
                        </div>
                      )
                    })}
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='species' className='form-label'>
                        Species
                      </label>
                      <select name='species' id='species' className='form-control' onChange={speciesChange}>
                        {optionData.map(data => {
                          return <option value={data.optionValue}>{data.optionValue}</option>
                        })}
                        {/* <option value='Mango'>Mango</option>
                        <option value='Neem'> Neem </option> */}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='Count' className='form-label'>
                        Count
                      </label>
                      {plantValue === "Multiple" ? <input type='text' name='count'
                        className='form-control ' id='count' placeholder='' value={addCount} onChange={countChange} />
                        : null}
                      {plantValue === "Single" ? <input type='text' name='count'
                        {...register("count", { required: "Count filed is required" })}
                        className='form-control ' id='count' placeholder='' /> : null}
                      {errors.count && (
                        <span className="form-error">
                          {errors.count.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {plantValue === "Multiple" ? <div className='col-md-4'>
                    <button className='btn btn-add' onClick={addSaplings}> Add </button>
                    <br />
                    {addError === true ?
                      <span className="form-error">
                        Add at least one Sapling
                      </span>
                      : null}
                    {countError === true ?
                      <span className="form-error">
                        Please add the count
                      </span>
                      : null}
                  </div> : null}
                  <div className='col-md-8'></div>

                  <div className='col-md-12 mg-top30'>
                    <h3 className='iniativecard-title'> ADD IMAGES </h3>

                    <div className='dis-flex cent-item-mob'>
                      {filesList && filesList.map(data => {
                        return (<img src={data.result} className='img-fluid' alt='Add image List' width="150px" height="150px" />)

                      })}

                      <div className='border-items'>
                        <span className='plus-icon-green btn-file'>
                          <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                          <input type="file" onChange={fileLoad} accept="image/png, image/gif, image/jpeg" />Add more
                        </span>
                        {/* <p><input type="file" onChange={fileLoad} /> Add more</p> */}
                      </div>
                    </div>
                    <div> <br />
                      {fileError === true ? <span className="form-error">Add at least one Image</span> : null}
                    </div>

                    <div className='text-right'>
                      <button className='btn btn-approve save-width'> Save </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          hideProgressBar
          closeOnClick
          rtl={false}
        />
        {/* {succes === true ? (
          <Modal show={succes}>
            <Spinner animation="border" variant="light" />
          </Modal>
        ) : null} */}
      </div>
    </>
  );
}

export default Plantationregister;