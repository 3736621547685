import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Plantationregister from 'components/register-plantation/plantationregister';



// markup
const Registerplantation = () => {
    return (
      <>
    <SEO title='Forests By Heartfulness | Register plantation' />
      <Layout>
     <section id='registerplantation'>
         <Plantationregister />
       </section>
      </Layout>
  </>
);
};

export default Registerplantation;